import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { Error, PickerArrowDown } from "../icons";
import { useCypressWarning } from "hooks";
import { Listbox } from "@headlessui/react";
import { Tooltip } from "components";

const getBackgroundColour = (variant, disabled, error) => {
  if (disabled && variant === "light") {
    return "bg-base-50";
  } else if (disabled && variant === "dark") {
    return "bg-primary-400";
  } else if (error) {
    return "bg-error-50";
  }

  return "bg-white";
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Picker({
  id,
  title,
  error,
  helpText,
  placeholder = "Choose option from list",
  value,
  disabled = false,
  onChange,
  options,
  variant = "dark",
  tooltipMessage,
  optionsReset = false,
  defaultValue,
  ...props
}) {
  useCypressWarning(id, `id missing for ${title} picker component`);

  const labelTextClass = variant === "light" ? "text-black" : "text-white";
  const helpTextClass =
    variant === "light" ? "text-primary-800" : "text-primary-100";

  const [selectedOption, setSelectedOption] = useState("");
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
  });

  function findSelectedOption(selectedValue) {
    if (selectedValue || selectedValue === 0) {
      return options.find(({ value }) => value === selectedValue) ?? "";
    }
    return "";
  }

  useEffect(() => {
    setSelectedOption(findSelectedOption(value));
  }, [value]);

  useEffect(() => {
    optionsReset && setSelectedOption(false);
  }, [options]);

  const changeEvent = (option) => {
    setSelectedOption(option);
    onChange(option?.value);
  };

  return (
    <div className="flex flex-col w-full relative">
      <Listbox
        as="div"
        data-cy={`${id}-dropdown`}
        nullable="true"
        value={selectedOption}
        onChange={changeEvent}
        disabled={disabled}
      >
        <Listbox.Label
          className={classNames("block text-sm font-medium", labelTextClass)}
        >
          {title}
        </Listbox.Label>
        {tooltipMessage && (
          <div className="flex sm:hidden absolute items-center top-0 right-0">
            <Tooltip message={tooltipMessage}>
              <Error className="text-base-900" />
            </Tooltip>
          </div>
        )}
        <div className="relative mt-2 text-sm">
          <Listbox.Button
            ref={setReferenceElement}
            data-cy={`${id}-dropdown-button`}
            className={classNames(
              "relative w-full py-2 pl-3 pr-10 text-left border border-gray-300 cursor-default shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm",
              error ? "border-error-500 border-2" : "border-base-300",
              defaultValue
                ? getBackgroundColour(variant, defaultValue, error)
                : getBackgroundColour(variant, disabled, error)
            )}
          >
            <span className="block truncate text-base-500">
              {defaultValue
                ? defaultValue
                : selectedOption
                ? selectedOption.title || selectedOption.name
                : placeholder}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <PickerArrowDown aria-hidden="true" />
            </span>
          </Listbox.Button>
          {tooltipMessage && (
            <div className="hidden sm:flex absolute items-center inset-y-0 -right-8">
              <Tooltip message={tooltipMessage}>
                <Error className="text-base-900" />
              </Tooltip>
            </div>
          )}
          <Listbox.Options
            ref={setPopperElement}
            className="absolute z-20 w-full py-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-30 focus:outline-none sm:text-sm"
            style={styles.popper}
            {...attributes.popper}
          >
            {options?.map((option, i) => (
              <Listbox.Option
                disabled={option.disabled}
                key={`${option.title}-${i}`}
                value={option}
                className={({ active, disabled }) =>
                  classNames(
                    "cursor-default select-none relative py-2 pl-3 pr-9",
                    active
                      ? "bg-base-50 text-base-500"
                      : disabled
                      ? "bg-base-50 text-base-500 cursor-not-allowed"
                      : "text-base-500"
                  )
                }
              >
                {() => (
                  <>
                    <span className={classNames("block")}>
                      {option.title || option.name}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
        {helpText && (
          <p className={`text-xs ${helpTextClass} mt-3`}>{helpText}</p>
        )}
      </Listbox>
    </div>
  );
}
