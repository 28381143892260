import React, { useCallback, useEffect, useState } from "react";
import { isEmpty, omit } from "lodash";
import { Button, Input, Picker, SearchablePicker } from "components";
import { useApp, useForm, useLocale } from "hooks";
import {
  getPostcodeFieldOptions,
  laCodeOptions,
  LOCALE_CA,
  LOCALE_US,
  getRegionOptions,
} from "support/constants";

import { schema } from "./AddressLookup.validation";
import { API } from "aws-amplify";

function getAddressPlaceholder(addressOptions) {
  if (addressOptions === undefined) {
    return "Enter postcode to find addresses";
  } else if (addressOptions.length === 0) {
    return "No addresses found";
  } else if (addressOptions.length > 0) {
    return `${addressOptions.length} addresses found`;
  }

  return "";
}

export default function AddressLookup({
  initialValues,
  clearError,
  validationProps,
  onChange,
  onCheckPostcode = async () => {},
  country = "GB",
  showLaCode = false,
  hideRegion = false,
}) {
  const postCodeFieldOptions = getPostcodeFieldOptions(country);

  const { regionPlaceholder, regionOptions, regionTitle } =
    getRegionOptions(country);

  const { addMessage } = useApp();
  const { locale } = useLocale();

  // if the validation props have numbers then the fields have already been set
  // and we should immediately show the inputs rather than the postcode search
  const [showAddressFields, setShowAddressFields] = useState(
    () =>
      country === "US" ||
      country === "CA" ||
      !Object.values(omit(validationProps, "postcode")).every((x) =>
        isEmpty(x.value)
      )
  );
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const [enterAddressManually, setEnterAddressManually] = useState(
    country === "US" || country === "CA"
  );

  const { fieldValues, setFieldValue } = useForm(
    initialValues || {
      postcode: "",
      houseNumber: "",
      street: "",
      city: "",
      laCode: "",
      region: "",
      latitude: "",
      longitude: "",
    },
    schema(country)
  );

  const addressFields = ["houseNumber", "street", "city", "region", "postcode"];

  const handleSearch = useCallback(async () => {
    fieldValues.postcode = fieldValues.postcode?.trim();

    const isValid = await onCheckPostcode(fieldValues.postcode);

    if (!isValid) {
      return;
    }

    setLoading(true);

    try {
      const result = await API.get(
        "UnauthenticatedAPI",
        `/addresses/${country}/${fieldValues.postcode}`
      );

      setAddressOptions(
        result.data.map((x) => ({
          title: x.fullAddress,
          value: JSON.stringify(x),
        }))
      );
    } catch (error) {
      console.log(error);
      addMessage(
        "error",
        "An error occurred when searching for addresses. Please try again."
      );
    }

    setLoading(false);
  }, [fieldValues.postcode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onChange) {
      onChange(fieldValues);
    }
  }, [fieldValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (country === "US" || country === "CA") {
      setShowAddressFields(true);
      setEnterAddressManually(true);
    } else {
      setEnterAddressManually(false);
    }
  }, [country]);

  const handleSearchAgain = useCallback(async () => {
    setFieldValue("houseNumber", "");
    setFieldValue("city", "");
    setFieldValue("street", "");
    setFieldValue("laCode", "");
    setFieldValue("postcode", "");
    setFieldValue("region", "");
    setFieldValue("latitude", "");
    setFieldValue("longitude", "");
    clearErrors(addressFields);
    setAddressOptions();
    setShowAddressFields(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateFieldsManually = (fieldName, value) => {
    // Clear geo location on 'dirty' addresses
    if (addressFields.includes(fieldName)) {
      setFieldValue("latitude", "");
      setFieldValue("longitude", "");
    }
    setFieldValue(fieldName, value); // Update the edited field
    clearError(fieldName); // Clear any existing validation error
  };

  const clearErrors = (fields) => fields.forEach((field) => clearError(field));

  return (
    <>
      {showAddressFields && (
        <div>
          {["US", "CA"].includes(country) ? (
            <div className="mb-4">
              <Input
                id="street"
                title="Address *"
                variant="dark"
                {...validationProps.street}
                value={fieldValues.street}
                onChange={(value) =>
                  handleUpdateFieldsManually("street", value)
                }
              />
            </div>
          ) : (
            <>
              <div className="mb-4">
                <Input
                  id="houseNumber"
                  title="House number *"
                  variant="dark"
                  {...validationProps.houseNumber}
                  value={fieldValues.houseNumber}
                  onChange={(value) =>
                    handleUpdateFieldsManually("houseNumber", value)
                  }
                />
              </div>
              <div className="mb-4">
                <Input
                  id="street"
                  title="Street *"
                  variant="dark"
                  {...validationProps.street}
                  value={fieldValues.street}
                  onChange={(value) =>
                    handleUpdateFieldsManually("street", value)
                  }
                />
              </div>
            </>
          )}
          <div className="mb-4">
            <Input
              id="city"
              title="City *"
              variant="dark"
              {...validationProps.city}
              value={fieldValues.city}
              onChange={(value) => handleUpdateFieldsManually("city", value)}
            />
          </div>
        </div>
      )}

      {[LOCALE_CA, LOCALE_US].includes(locale) && !hideRegion && (
        <div className="mb-4">
          <Picker
            id="region"
            title={regionTitle}
            placeholder={regionPlaceholder}
            options={regionOptions}
            {...validationProps.region}
            value={fieldValues.region}
            onChange={(value) => handleUpdateFieldsManually("region", value)}
          />
        </div>
      )}

      {showAddressFields && showLaCode && (
        <div className="mb-4">
          <SearchablePicker
            id="laCode"
            title="LA Code *"
            options={laCodeOptions.sort((a, b) =>
              a.title.localeCompare(b.title)
            )}
            placeholder="Choose LA Code"
            {...validationProps.laCode}
            value={fieldValues.laCode}
            onChange={(value) => handleUpdateFieldsManually("laCode", value)}
          />
        </div>
      )}

      <div className="mb-4">
        <Input
          id="postcode"
          title={postCodeFieldOptions.title}
          variant="dark"
          subtitle={postCodeFieldOptions.subtitle}
          placeholder={`Type ${postCodeFieldOptions.title.toLowerCase()}`}
          {...validationProps.postcode}
          value={fieldValues.postcode}
          onChange={(value) => handleUpdateFieldsManually("postcode", value)}
          disabled={loading}
        />
      </div>

      {!enterAddressManually && (
        <div>
          <Button
            type="button"
            id="search"
            className="mb-4"
            title={showAddressFields ? "Search Again" : "Search"}
            variant={loading ? "disabled" : "offWhite"}
            disabled={loading}
            loading={loading}
            fillStyle="outline"
            onClick={showAddressFields ? handleSearchAgain : handleSearch}
          />
        </div>
      )}

      {!showAddressFields && (
        <>
          <p
            data-cy="enter-address-manually"
            onClick={() => {
              setShowAddressFields(true);
              setEnterAddressManually(true);
            }}
            className="mb-4 text-xs font-bold text-white underline cursor-pointer"
          >
            Enter address manually
          </p>
          <div className="mb-8">
            <Picker
              variant="dark"
              id="address"
              title="Select Address *"
              disabled={
                addressOptions === undefined || addressOptions.length === 0
              }
              placeholder={getAddressPlaceholder(addressOptions)}
              options={addressOptions}
              onChange={(val) => {
                const address = JSON.parse(val);

                const houseNumber = [
                  address.subBuildingName.trim(),
                  address.buildingNumber.trim() || address.buildingName,
                ]
                  .filter((s) => !!s)
                  .join(", ");

                const street =
                  address.dependentThoroughfare || address.thoroughfare;

                setFieldValue("houseNumber", houseNumber);
                setFieldValue("street", street);
                setFieldValue("postcode", address.postcode);
                setFieldValue("city", address.town);
                setFieldValue("latitude", address.extra.latitude ?? "");
                setFieldValue("longitude", address.extra.longitude ?? "");

                clearErrors(addressFields);

                // FIXME: Yes this is horrible magic value nonsense,
                //        but it's the only way to get the "LACode" for
                //        Crown Dependencies with the data we currently have.

                let laCode = address.extra.codes.admin_district;

                if (laCode === "M99999999") {
                  laCode = "IM01";
                } else if (laCode === "L99999999") {
                  laCode =
                    address.extra.nhs_ha === "Guernsey Health Authority"
                      ? "GUER"
                      : "JE01";
                }

                setFieldValue("laCode", laCode);

                setShowAddressFields(true);
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
