import { useCallback, useEffect, useState } from "react";
import { get, omit, set } from "lodash";

function useFieldValues(initialValues) {
  const [fieldValues, setFieldValues] = useState(initialValues);

  const setFieldValue = (key, value) =>
    setFieldValues((fv) => {
      const updatedValues = { ...fv };
      set(updatedValues, key, value);
      return updatedValues;
    });

  const resetValues = () => setFieldValues(initialValues);

  return {
    fieldValues,
    setFieldValue,
    resetValues,
  };
}

function useFieldErrors(schema) {
  const [fieldErrors, setFieldErrors] = useState({});

  const validateFieldValues = useCallback(
    (values) => {
      return schema
        .validate(values, { abortEarly: false })
        .then(() => true)
        .catch((e) => {
          setFieldErrors(
            e.inner.reduce(
              (acc, { path, errors }) =>
                Object.assign(acc, { [path]: errors.join(". ") }),
              {}
            )
          );
          return false;
        });
    },
    [schema]
  );

  const validateSingleField = useCallback(
    (value, fieldNamePath) => {
      return schema
        .validateAt(fieldNamePath, set({}, fieldNamePath, value))
        .then(() => {
          return true;
        })
        .catch((e) => {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldNamePath]: e.errors.join(". "),
          }));
          return false;
        });
    },
    [schema]
  );

  const clearError = (fieldNamePath) => {
    const newFieldErrors = omit(fieldErrors, fieldNamePath);
    setFieldErrors(newFieldErrors);
  };

  const clearAllErrors = () => {
    setFieldErrors({});
  };

  const hasErrors = Object.entries(fieldErrors).length > 0;

  return {
    clearError,
    clearAllErrors,
    fieldErrors,
    hasErrors,
    validateFieldValues,
    validateSingleField,
  };
}

function useTouchedFields() {
  const [touchedFields, setTouchedFields] = useState([]);

  const touchField = useCallback(
    (fieldName) => {
      setTouchedFields((fields) => [...new Set([...fields, fieldName])]);
    },
    [setTouchedFields]
  );

  return {
    touchedFields,
    touchField,
  };
}

export default function useForm(initialValues, schema, onFormChange) {
  const { fieldValues, setFieldValue, resetValues } =
    useFieldValues(initialValues);
  const { touchedFields, touchField } = useTouchedFields();
  const {
    hasErrors,
    fieldErrors,
    clearError,
    clearAllErrors,
    validateFieldValues,
    validateSingleField,
  } = useFieldErrors(schema);

  const getFieldProps = useCallback(
    (key) => {
      const onChange = (value) => {
        setFieldValue(key, value);
        touchField(key);
        clearError(key);
      };

      return {
        onChange,
        value: get(fieldValues, key),
        error: !!get(fieldErrors, key),
        helpText: get(fieldErrors, key),
      };
    },
    [fieldValues, fieldErrors, setFieldValue, touchField, clearError]
  );

  const resetForm = useCallback(() => {
    resetValues();
    clearAllErrors();
  }, [resetValues, clearAllErrors]);

  useEffect(() => {
    if (onFormChange) {
      onFormChange(fieldValues);
    }
  }, [fieldValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    getFieldProps,
    fieldValues,
    fieldErrors,
    clearError,
    clearAllErrors,
    hasErrors,
    touchedFields,
    validateFieldValues,
    validateSingleField,
    resetForm,
    setFieldValue,
  };
}
